import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import SEO from '../components/SEO';
import { COLORS } from '../constants/style';

const PrivacyPolicyWrapper = styled.article`
  .header {
    padding-top: 130px;
    padding-bottom: 70px;
    background: ${COLORS.bgGray};
    text-align: center;

    h1 {
      font-size: 3.5em;
      color: ${COLORS.brightGold};
    }
  }

  .article-container {
    max-width: 700px;
    width: 90%;
    margin: auto;
  }

  .content {
    padding-top: 50px;
    padding-bottom: 50px;

    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 1.5em;
      margin-bottom: 0.5em;
    }
  }
`;

export default function PrivacyPolicy({ data }) {
  const { content, seoMetaTags } = data.page;
  return (
    <>
      <SEO meta={seoMetaTags} />
      <PrivacyPolicyWrapper>
        <div className="header">
          <h1>Edcellent Privacy Policy</h1>
        </div>
        <article className="article-container">
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </article>
      </PrivacyPolicyWrapper>
    </>
  );
}

export const privacyPolicyQuery = graphql`
  {
    page: datoCmsPrivacyPolicyPage {
      content
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;
